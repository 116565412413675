import {Button} from "@/components/ui/button"
import {Logo} from "@/components/ui/logo"
import {useTranslation} from "react-i18next"
import {LanguageButton} from "./LanguageButton"
import {MobileMenu} from "./MobileMenu"
import {UserButton} from "./UserButton"

export const Navbar = () => {
  const {t} = useTranslation()

  return (
    <nav className={"flex items-center justify-between p-4"}>
      {/* Logo and Buttons */}
      <div className='flex items-center space-x-4'>
        <Logo variant='long' />
        <div className='hidden md:flex space-x-2'>
          <Button variant='ghost'>{t("nav.wallets")}</Button>
          <Button variant='ghost'>{t("nav.recipients")}</Button>
          <Button variant='ghost'>{t("nav.payment_requests")}</Button>
        </div>
      </div>

      {/* Desktop Right Side */}
      <div className='hidden md:flex items-center space-x-2'>
        <LanguageButton />
        <UserButton />
      </div>

      {/* Mobile Menu */}
      <div className='md:hidden'>
        <MobileMenu />
      </div>
    </nav>
  )
}
