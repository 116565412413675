const LogoFullDark = () => (
  <svg
    width='228'
    height='44'
    viewBox='0 0 228 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Walletory</title>
    <g clipPath='url(#clip0_381_2503)'>
      <path
        d='M219.578 19.7663L211.241 43.2549C212.549 43.7113 213.948 43.9689 215.413 43.9689C222.363 43.9689 228 38.3902 228 31.5106C228 26.0777 224.481 21.4707 219.581 19.7663H219.578Z'
        fill='#F2B617'
      />
      <path
        d='M0 9.28241H7.65029L11.1037 23.4513H12.0918L15.793 9.28241H23.1987L26.8999 23.4513H27.888L31.3414 9.28241H38.9917L32.5741 34.1991H23.4433L19.9867 20.2754H18.9987L15.5421 34.1991H6.41131L0 9.28241Z'
        fill='#544F9D'
      />
      <path
        d='M57.2564 32.0012H57.0086C57.0086 32.0012 54.7878 34.6896 50.0986 34.6896C44.1766 34.6896 40.72 31.2685 40.72 26.8725C40.72 22.2313 44.1766 18.8133 51.0866 18.8133H56.7639V18.3258C56.7639 16.1279 55.2834 14.6625 52.8149 14.6625C49.6061 14.6625 48.8658 16.8605 48.8658 16.8605H41.708C42.2005 12.4646 45.9049 8.79816 52.8149 8.79816C60.2205 8.79816 64.1696 12.7067 64.1696 18.2762V34.2022H57.752L57.2595 32.0043L57.2564 32.0012ZM56.7639 23.6966H52.0746C49.3614 23.6966 48.1256 24.9198 48.1256 26.3851C48.1256 27.8504 49.3614 29.0705 51.5822 29.0705C54.791 29.0705 56.7639 27.1178 56.7639 24.184V23.6966Z'
        fill='#544F9D'
      />
      <path d='M70.0884 0H77.4941V34.1991H70.0884V0Z' fill='#544F9D' />
      <path d='M83.413 0H90.8186V34.1991H83.413V0Z' fill='#544F9D' />
      <path
        d='M120.516 26.6273C120.516 26.6273 117.8 34.6896 108.421 34.6896C100.771 34.6896 95.3416 29.3158 95.3416 21.7439C95.3416 14.172 100.771 8.79816 108.421 8.79816C116.072 8.79816 121.257 14.172 121.257 21.2565C121.257 22.9671 121.009 23.6997 121.009 23.6997H102.744C102.992 26.6304 105.213 28.8284 108.421 28.8284C111.63 28.8284 113.111 26.6304 113.111 26.6304H120.516V26.6273ZM114.102 19.2976C113.362 16.1217 111.389 14.4111 108.425 14.4111C105.46 14.4111 103.488 16.1217 102.747 19.2976H114.102Z'
        fill='#544F9D'
      />
      <path
        d='M127.426 16.1217H123.477V9.28245H127.426V1.46533H134.832V9.28245H140.509V16.1217H134.832V25.6493C134.832 26.8694 135.325 27.3599 136.56 27.3599H140.509V34.1991H134.587C129.898 34.1991 127.43 31.7559 127.43 27.3599V16.1217H127.426Z'
        fill='#544F9D'
      />
      <path
        d='M143.712 21.7408C143.712 14.1689 149.141 8.79504 156.792 8.79504C164.442 8.79504 169.872 14.1689 169.872 21.7408C169.872 29.3126 164.442 34.6865 156.792 34.6865C149.141 34.6865 143.712 29.3126 143.712 21.7408ZM162.469 21.7408C162.469 18.0775 160.001 15.6342 156.792 15.6342C153.583 15.6342 151.114 18.0775 151.114 21.7408C151.114 25.4041 153.583 27.8473 156.792 27.8473C160.001 27.8473 162.469 25.4041 162.469 21.7408Z'
        fill='#544F9D'
      />
      <path
        d='M173.573 9.28241H179.99L180.483 11.2351H180.731C180.731 11.2351 181.966 9.28241 185.172 9.28241H189.861V16.1216H185.42C182.459 16.1216 180.978 17.5869 180.978 20.5176V34.196H173.573V9.28241Z'
        fill='#544F9D'
      />
      <path
        d='M194.551 37.1297H199.488C200.724 37.1297 201.069 36.6423 201.216 36.1518L201.464 35.4191L191.345 9.28241H199.243L205.165 24.9166H206.153L211.09 9.28241H218.988L208.622 38.1076C207.141 42.2615 205.413 43.9689 199.736 43.9689H194.554V37.1297H194.551Z'
        fill='#544F9D'
      />
    </g>
    <defs>
      <clipPath id='clip0_381_2503'>
        <rect width='228' height='44' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

LogoFullDark.displayName = "Walletory Logo"

export {LogoFullDark}
