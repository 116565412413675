import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card"
import {useTranslation} from "react-i18next"
import {useMachine} from "@xstate/react"
import {authMachine} from "../machine"
import {Spinner} from "@/components/ui/spinner"
import {FACodeForm} from "./FACodeForm"
import {LoginForm} from "./LoginForm"
import {Alert, AlertTitle} from "@/components/ui/alert"
import {ExclamationTriangleIcon} from "@radix-ui/react-icons"

export const LoginCard = () => {
  const {t} = useTranslation()
  const [state, send] = useMachine(authMachine)

  const handleSubmitCredentials = (username: string, password: string) =>
    send({type: "SUBMIT_CREDENTIALS", username, password})
  const handle2faCode = (code2FA: string) => send({type: "SUBMIT_2FA", code2FA})

  return (
    <Card className='w-full max-w-sm'>
      <CardHeader>
        <CardTitle className='text-2xl'>
          {state.matches("idle") && t("auth.login.header")}
          {state.matches("entering2FA") && t("auth.2fa.header")}
        </CardTitle>
        <CardDescription>
          {state.matches("idle") && t("auth.login.sub_header")}
          {state.matches("entering2FA") && t("auth.2fa.sub_header")}
        </CardDescription>
      </CardHeader>
      <CardContent className='grid gap-4'>
        {state.context.error && (
          <Alert variant='destructive'>
            <ExclamationTriangleIcon className='h-4 w-4' />
            <AlertTitle>{t(state.context.error)}</AlertTitle>
            {/* <AlertDescription>
            {t(state.context.error)}
          </AlertDescription> */}
          </Alert>
        )}
        {state.matches("idle") && (
          <LoginForm handleLogin={handleSubmitCredentials} />
        )}
        {state.matches("loggingIn") && <Spinner />}
        {state.matches("requesting2FA") && <Spinner />}
        {state.matches("entering2FA") && (
          <FACodeForm handle2fa={handle2faCode} />
        )}
        {state.matches("verifying2FA") && <Spinner />}
      </CardContent>
    </Card>
  )
}
