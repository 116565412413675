import {authClient} from "../clients"

const OAUTH_CLIENT_ID = 2
const OAUTH_REDIRECT_URI = "https://app.develop.walletory.net/"

export const login = (username: string, password: string) =>
  authClient.POST("/api/v1/login", {body: {username, password}})

export const request2fa = (token: string) =>
  authClient.POST("/api/v1/request-2fa", {body: {token}})

export const verify2fa = (code: string, token: string) =>
  authClient.POST("/api/v1/verify-2fa", {body: {code, token}})

// OAuth

export const authorize = (codeChallenge: string) =>
  authClient.GET("/api/v1/oauth/authorize", {
    params: {
      query: {
        client_id: OAUTH_CLIENT_ID,
        redirect_uri: OAUTH_REDIRECT_URI,
        response_type: "code",
        scope: "",
        state: "id",
        code_challenge: codeChallenge,
        code_challenge_method: "S256",
      },
    },
  })

export const accessToken = (codeVerifier: string) =>
  authClient.POST("/api/v1/oauth/token", {
    body: {
      grant_type: "authorization_code",
      client_id: OAUTH_CLIENT_ID,
      redirect_uri: OAUTH_REDIRECT_URI,
      code_verifier: codeVerifier,
      code: "todo-query-param-from-authorize-redirect-url",
    },
  })
