import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar"
import {Button} from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {UserRound} from "lucide-react"
import {useTranslation} from "react-i18next"

export const UserButton = () => {
  const {t} = useTranslation()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={"ghost"}>
          <Avatar className='mr-2'>
            <AvatarImage src='/placeholder.svg?height=32&width=32' alt='User' />
            <AvatarFallback>
              <div className='flex h-[36px] w-[36px] items-center justify-center rounded-full bg-accent'>
                <UserRound />
              </div>
            </AvatarFallback>
          </Avatar>
          Example name
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem>{t("profile.dropdown_menu.manage")}</DropdownMenuItem>
        <DropdownMenuItem>{t("profile.dropdown_menu.logout")}</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
