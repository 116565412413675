import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar"
import {Button} from "@/components/ui/button"
import {Logo} from "@/components/ui/logo"
import {Separator} from "@/components/ui/separator"
import {Sheet, SheetContent, SheetTrigger} from "@/components/ui/sheet"
import {Contact, HandCoins, LogOut, Menu, Settings, Wallet} from "lucide-react"
import {useTranslation} from "react-i18next"

export const MobileMenu = () => {
  const {t} = useTranslation()
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant='ghost' size='icon'>
          <Menu className='h-6 w-6' />
        </Button>
      </SheetTrigger>
      <SheetContent side='right'>
        <div className='flex flex-col h-full'>
          <div className='flex justify-between items-center mb-6'>
            <Logo />
          </div>

          <div className='flex items-center space-x-2'>
            <Avatar>
              <AvatarImage
                src='/placeholder.svg?height=32&width=32'
                alt='User'
              />
              <AvatarFallback>JD</AvatarFallback>
            </Avatar>
            <span>John Doe</span>
          </div>
          <Separator className='my-4' />
          <div className='flex space-y-4 flex-col items-start'>
            <Button variant='ghost'>
              <Wallet className='mr-2 h-4 w-4' />
              {t("nav.wallets")}
            </Button>
            <Button variant='ghost'>
              <Contact className='mr-2 h-4 w-4' />
              {t("nav.recipients")}
            </Button>
            <Button variant='ghost'>
              <HandCoins className='mr-2 h-4 w-4' />
              {t("nav.payment_requests")}
            </Button>
          </div>
          <Separator className='my-4' />
          <div className='flex space-y-4 flex-col items-start'>
            <Button variant='ghost'>
              <Settings className='mr-2 h-4 w-4' />
              {t("profile.dropdown_menu.manage")}
            </Button>
            <Button variant='ghost'>
              <LogOut className='mr-2 h-4 w-4' />
              {t("profile.dropdown_menu.logout")}
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
