import {login, request2fa, verify2fa} from "@/api/auth"
import {assign, createMachine, fromPromise} from "xstate"

// Define the context interface for our machine
interface AuthContext {
  username?: string
  password?: string
  tempToken?: string
  code2FA?: string
  hashedChallenge?: string
  originalChallenge?: string
  error?: string
}

// Define the event types
type AuthEvent =
  | {type: "SUBMIT_CREDENTIALS"; username: string; password: string}
  | {type: "RETRY"}
  | {type: "SUBMIT_2FA"; code2FA: string}

// Create the state machine
export const authMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgOgJYQBswBiAZQFUAhAWQEkAVAfQGEAlAUQBF2A5e2gIIAZUgG0ADAF1EoAA4B7WLnS55AOxkgAHogCsANgCM2fQHYAnPoAcp3QBoQAT0SHTAFmzndAZkO+rPt7mpgEAvqEOaFjYBPJQULhqULRqxBDqYHhqAG7yANaZsQlqpGAATtm4AMZgEtJIIApKKuqaOgj64qbYurpu1rYOzghWxrriE+LWgcFhESBROEXFyanlZfJl2LIEyOgAZpsAtjFxiaUV1bVSmk3KqhoN7V762Kb6BgBM9k4u+t7YcTefT9XSGKxmXyGfThSIYHBlMAAR1QcBUSU+ADEBGkMllcgVsIiUWjElAsQI6rdFPdWk9EJ9PsYpt8hohpthvN5dOY3G5uVzZrCFvCicjUbB0eTscR1pttrsDscxSTJWSKVSGncWo9QO1Gcz9Kzfgh+gDzCyDAFBSFdMLFtgwGp0OV1TKKDQGIwNTctTSdW09EYTBYBj9hvpOtgAuZvKNrUEQvbRdlXftHG6cek1JlEgTMqmyrh0z76nJ-Q9AwgXm8Pkbw+zzOYejG+QLE1Zk9FC8WMxiZXKtjs9ocyiceyXsZry81K-TqwZa18Gx0ptg-J9RrGZkn5g79shcARUIjiBx6KwAJrTxoVul6lziTfR8xWfmDE3+EOWXntoXCtR5AgOBNEWalZ3vbREAAWn0NkEGg3RPCbFDUJQ3RTC7HB8CIcDaV1KDTVMAFXEsGw3DQ3l4K5V4MM+cxPm5T4JhBO091FZYyRSPCA3nCxPjeIFDGNYZoTGPwn3eCFiMMaEsJVCUpQpHi5wfU1Png-4rGwJlxF5fkd07djoidF0i37AQVMg9o3EMZtDGXTS305bl9L-Xc4W7NM+2lSy-Qggj2n4wTfBE9koysVy20M+TYFQKoalgeB-PwqtZPGHoQmEld9GCFyeWim0jM8nADyPE8wCswKXAc8RMtGMLTUinpvE3Oz3KM8IgA */
  id: "auth",
  initial: "idle",
  types: {
    events: {} as AuthEvent,
    context: {} as AuthContext,
  },
  context: {
    username: undefined,
    password: undefined,
    tempToken: undefined,
    code2FA: undefined,
    error: undefined,
  },
  states: {
    idle: {
      on: {
        SUBMIT_CREDENTIALS: {
          target: "loggingIn",
          actions: assign({
            username: ({event}) => event.username,
            password: ({event}) => event.password,
          }),
        },
      },
    },
    loggingIn: {
      invoke: {
        id: "login",
        input: ({context: {username, password}}) => ({username, password}),
        src: fromPromise(({input}) =>
          login(input.username, input.password).then(({data, error}) => {
            if (error) throw new Error(error.code)
            return data.token
          }),
        ),
        onDone: {
          target: "requesting2FA",
          actions: assign({
            tempToken: ({event}) => event.output,
            error: undefined,
          }),
        },
        onError: {
          target: "idle",
          actions: assign({
            // @ts-ignore
            error: ({event}) => event.error?.message,
          }),
        },
      },
    },
    requesting2FA: {
      invoke: {
        id: "request2FA",
        input: ({context: {tempToken}}) => ({tempToken}),
        src: fromPromise(({input}) =>
          request2fa(input.tempToken).then(({data, error}) => {
            if (error) throw new Error(error.code)
            return data
          }),
        ),
        onDone: {
          target: "entering2FA",
          actions: assign({
            error: undefined,
          }),
        },
        onError: {
          target: "entering2FA",
          actions: assign({
            // @ts-ignore
            error: ({event}) => event.error?.message,
          }),
        },
      },
    },
    entering2FA: {
      on: {
        SUBMIT_2FA: {
          target: "verifying2FA",
          actions: assign({
            code2FA: ({event}) => event.code2FA,
          }),
        },
      },
    },
    verifying2FA: {
      invoke: {
        id: "verify2FA",
        input: ({context: {tempToken, code2FA}}) => ({tempToken, code2FA}),
        src: fromPromise(({input}) =>
          verify2fa(input.code2FA, input.tempToken).then(({data, error}) => {
            if (error) throw new Error(error.code)
            return data
          }),
        ),
        onDone: {
          target: "success",
          actions: assign({
            error: undefined,
          }),
        },
        onError: {
          target: "entering2FA",
          actions: assign({
            // @ts-ignore
            error: ({event}) => event.error?.message,
          }),
        },
      },
    },
    // OAuth
    // authorizing: {
    //     invoke: {
    //         id: 'authorize',
    //         input: ({ context: { username, password } }) => ({ username, password }),
    //         src: fromPromise(({input}) => authorize(input.username).then(({data, error}) => {
    //             if (error) throw new Error('something');

    //             return data
    //           })),
    //         onDone: {
    //           target: 'gettingAccessToken',
    //           actions: assign({
    //             tempToken: ({ event }) => event.output,
    //           }),
    //         },
    //         onError: {
    //           target: 'failure',
    //           actions: assign({
    //             error: ({ event }) => event.error,
    //           }),
    //         },
    //       },
    // },
    // gettingAccessToken: {},
    success: {
      type: "final",
    },
    failure: {
      on: {
        RETRY: {
          target: "idle",
          actions: assign({code2FA: undefined, error: undefined}),
        },
      },
    },
  },
})
