import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import {Outlet, createRootRoute} from "@tanstack/react-router"
import {TanStackRouterDevtools} from "@tanstack/router-devtools"

export const Route = createRootRoute({
  component: RootComponent,
})

function RootComponent() {
  return (
    <>
      <Outlet />
      <ReactQueryDevtools initialIsOpen={false} />
      <TanStackRouterDevtools position='bottom-right' />
    </>
  )
}
