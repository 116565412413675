import {Button} from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {Input} from "@/components/ui/input"
import {zodResolver} from "@hookform/resolvers/zod"
import type {FC} from "react"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {z} from "zod"

interface Props {
  handleLogin: (username: string, password: string) => void
  error?: string
}

export const LoginForm: FC<Props> = ({handleLogin}) => {
  const {t} = useTranslation()

  const formSchema = z.object({
    username: z.string().min(1, {
      message: "",
    }),
    password: z.string().min(1, {
      message: "",
    }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
    },
  })

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    handleLogin(values.username, values.password)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        <div className='grid gap-2'>
          <FormField
            control={form.control}
            name='username'
            render={({field}) => (
              <FormItem>
                <FormLabel>{t("auth.login.label_email")}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({field}) => (
              <FormItem>
                <FormLabel>{t("auth.login.label_password")}</FormLabel>
                <FormControl>
                  <Input {...field} type='password' />
                </FormControl>
              </FormItem>
            )}
          />
          <FormMessage />
          <Button type='submit' className='w-full mt-4'>
            {t("auth.login.button_login")}
          </Button>
        </div>
      </form>
    </Form>
  )
}
