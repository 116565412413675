// biome-ignore lint: obvious
import * as React from "react"
import {createFileRoute} from "@tanstack/react-router"

export const Route = createFileRoute("/_protected/")({
  component: AboutComponent,
})

function AboutComponent() {
  return (
    <div className='p-2'>
      <h3>Wallets</h3>
    </div>
  )
}
