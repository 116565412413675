import {Navbar} from "@/components/layouts/Navbar"
import {Outlet, createFileRoute} from "@tanstack/react-router"

// src/routes/_protected.tsx
export const Route = createFileRoute("/_protected")({
  component: RootComponent,
  // beforeLoad: async ({ location }) => {
  //   if (!isAuthenticated()) {
  //     throw redirect({
  //       to: "/login",
  //       search: {
  //         // Use the current location to power a redirect after login
  //         // (Do not use `router.state.resolvedLocation` as it can
  //         // potentially lag behind the actual current location)
  //         redirect: location.href,
  //       },
  //     });
  //   }
  // },
})

function RootComponent() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  )
}
