// biome-ignore lint:
import React from "react"
import ReactDOM from "react-dom/client"
import {RouterProvider, createRouter} from "@tanstack/react-router"
import {routeTree} from "./routeTree.gen"
// @ts-ignore
import "./i18n"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {ThemeProvider} from "./features/provider/ThemeProvider"
import "./globals.css"

const queryClient = new QueryClient()

// Set up a Router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
})

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

// biome-ignore lint: Classic
const rootElement = document.getElementById("app")!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>,
  )
}
