// biome-ignore lint: obvious
import * as React from "react"
import {createFileRoute} from "@tanstack/react-router"
import {Logo} from "@/components/ui/logo"
import {LoginCard} from "@/features/auth/LoginComponent"

export const Route = createFileRoute("/login")({
  component: LoginComponent,
})

function LoginComponent() {
  return (
    <main className='flex justify-center items-center min-h-screen w-full'>
      <div>
        <div className='flex justify-center mb-8'>
          <Logo variant='long' />
        </div>
        <LoginCard />
      </div>
    </main>
  )
}
