const LogoShortDark = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='69'
    height='36'
    viewBox='0 0 69 36'
    fill='none'
  >
    <title>Walletory logo</title>
    <g clipPath='url(#clip0_617_708)'>
      <path
        d='M58.9331 9.72583L52.3374 35.5149C53.3951 35.7816 54.496 35.9416 55.6378 35.9416C63.0185 35.9416 69.0025 29.9776 69.0025 22.6216C69.0025 16.4011 64.7184 11.1914 58.9331 9.72583Z'
        fill='#F1B519'
      />
      <path
        d='M0 0H10.8365L15.7298 20.2086H17.1289L22.3739 0H32.8613L38.1037 20.2086H39.5028L44.3987 0H55.2352L46.147 35.5403H33.213L28.3172 15.6797H26.918L22.0222 35.5403H9.0882L0 0Z'
        fill='#554596'
      />
    </g>
    <defs>
      <clipPath id='clip0_617_708'>
        <rect width='69' height='36' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

LogoShortDark.displayName = "Walletory Logo"

export {LogoShortDark}
