import type { FC } from "react";
import { LogoFullDark } from "./logo-full-dark";
import { LogoFullLight } from "./logo-full-light";
import { LogoShortDark } from "./logo-short-dark";
import { LogoShortLight } from "./logo-short-light";
import { useTheme } from "@/features/provider/ThemeProvider";

interface Props {
  variant?: "long" | "short";
}

// Return Logo based on theme and variant
export const Logo: FC<Props> = ({ variant = "short" }) => {
  const { theme = "light" } = useTheme();

  if (variant === "long") {
    if (theme === "dark") return <LogoFullLight />;

    return <LogoFullDark />;
  }

  if (variant === "short") {
    if (theme === "dark") return <LogoShortLight />;

    return <LogoShortDark />;
  }

  return <LogoShortDark />;
};
